import React from 'react'
import "./LoadingFull.scss"

export const LoadingFull = () => {
  return (
    <div className="loading-full-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}
