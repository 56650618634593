import React, { useEffect } from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./Home.scss";
import Banner from "../../components/Banner/Banner";
import HowItWork from "../../components/HowItWork/HowItWork";
import WhyChooseUS from "../../components/WhyChooseUS/WhyChooseUS";
import ReadyToApply from "../../components/ReadyToApply/ReadyToApply";
// import { FormTop } from "../../components/FormTop/FormTop";
import ScrollToTop from "react-scroll-to-top";

const Home = () => {

  return (
    <div
      className="wrapper"
      style={{
        overflowX: "hidden"
      }}
    >
      <ScrollToTop smooth color="#278BA2" />
      <Navbar />
      {/* <FormTop /> */}
      <Banner />
      <HowItWork style={{ zIndex: "-1" }} />
      <WhyChooseUS />
      <ReadyToApply />
      <Footer />
    </div>
  );
};

export default Home;
