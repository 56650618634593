import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./LoanReason.scss";

import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import loanReasonIcon from "../../../assets/images/step-icon/step1-icon.png";

import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateContact } from "../../../redux/apiCalls";
import { useDispatch } from "react-redux";

import { Box, FormControl, MenuItem, Select } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { MenuProps, labelCustom, menuItemCustom, selectCustom } from "./style";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { loanReasonOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";
import { checkLastStep } from "../../../utils/checkLastStep";

// CUSTOM STYLE
const LoanReason = () => {
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loanReason, setLoanReason] = useState(
    contactRedux?.loanReason ?? loanReasonOptions[2].value
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // custom
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({});

  const isShortForm = checkLastStep (contactRedux) === "done"

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);
    
    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      ...data,
      lead_id,
      step: 1
    });
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        const lastStep = checkLastStep (contactRedux);
          lastStep === "done" ? navigate(`/nextpaydatesort`) : navigate("/lgname");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-1">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        > 
          {/* the number 7 below is just randomly choose by dev, can be changed as desire */}
          <Progress step={isShortForm ? 7 : 1} />
        </Box>
        <Box
          className="step-main"
          sx={{
            minHeight: "500px"
          }}
        >
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={loanReasonIcon}
                alt=""
              />
            </Box>
            <h2 className="title">
              What is your loan reason?
            </h2>
            <div className="form-control">
              <div className="box-field">
                <Controller
                  name="loanReason"
                  defaultValue={loanReason}
                  control={control}
                  rules={{ required: "Loan reason is required!" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.loanReason}
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Reason for loan
                      </FormHelperText>
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setLoanReason(value);
                        }}
                        value={loanReason}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={selectCustom}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        className={isSelectOpen ? "show" : ""}
                      >
                        {loanReasonOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.loanReason && errors?.loanReason.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink="/start"
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
        </Box>
      </Box>
      <Footer desc={false} />
    </Box>
  );
};

export default LoanReason;
