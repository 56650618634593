import React, { useEffect } from "react";

import "./RedirectPageUpstart.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Box } from "@material-ui/core";
import MyCircularProgressbar from "../../../components/CircularProgressbar/CircularProgressbar";

const RedirectPageUpstart = () => {
  const windowWidth = window.innerWidth;

  useEffect(() => {
    setTimeout(() => {
      const redirectURL = localStorage.getItem("redirectURL");
      if (redirectURL) window.location.href = redirectURL;
    }, 2000);
  });

  return (
    <Box
      className="redirect-upstart"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh"
      }}
    >
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>
      <Box
        sx={{
          width: "100%",
          padding: "0 15px"
        }}
      >
        <div className="step-main">
          <div className="redirect-content">
            <h1>We've found the program for you!</h1>
            <h3 className="configuration">Congratulation!</h3>
            <h3 className="text">
              We are connecting you with a program who is a
              {windowWidth < 768 && <br />} perfect fit for you. Just a few
              {windowWidth > 768 && <br />} more steps to get
              {windowWidth < 768 && <br />} in touch with the lender!
            </h3>

            <MyCircularProgressbar page="upstart" />

            {/* <div className="loading-text">
              <h3>
                REDIRECTING
                <span className="dot-one"> .</span>
                <span className="dot-two"> .</span>
                <span className="dot-three"> .</span>
              </h3>
            </div> */}
          </div>
        </div>
      </Box>

      <footer>
        <p>© 2024 Hanfincal.com All rights reserved.</p>
      </footer>
    </Box>
  );
};

export default RedirectPageUpstart;
