import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import DataProtected from "../../assets/images/security/DataProtected.png";
import SSL from "../../assets/images/security/SSL.png";
import Clear from "../../assets/images/security/Clear.png";

import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";

import { RatesFee } from "../popups/RatesFee/RatesFee";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import "./Banner.scss";
import {
  ListBoxCustom,
  MenuProps,
  btnSubmitCustom,
  labelCustom,
  menuItemCustom,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { amountOptions, emailDomainsOptions } from "../../utils/options";
import axios from "axios";
import {
  convertContactFromResponse,
  handleLastStep
} from "../../utils/functions";
import { sendDataBrevo } from "../../utils/callApi";
import { LoadingFull } from "../LoadingFull/LoadingFull";
import Cookies from "js-cookie";

const Banner = () => {
  const isMobile = window.innerWidth < 768;

  const [openRateFee, setOpenRateFee] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [amount, setAmount] = useState(
    contactRedux?.amount ?? contactRedux?.loanAmount ?? amountOptions[3]?.value
  );

  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const [email, setEmail] = useState(contactRedux?.email ?? "");

  const [ipAddress, setIpAddress] = useState(contactRedux?.ipAddress ?? "");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const emailFromURL = searchParams.get("email");
  const loanReasonFromURL = searchParams.get("loan_reason");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // set sessionStorage
  loanReasonFromURL &&
    sessionStorage.setItem("loanReasonFromURL", loanReasonFromURL);

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const yourIp = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/your-ip`
        );
        const res = yourIp?.data.ip;
        if (res) {
          setIpAddress(res);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    // get ip address if ipAddress not exist
    !ipAddress && getIpAddress();

    const fetchData = async () => {
      if (emailFromURL && loanReasonFromURL && ipAddress) {
        setShowLoading(true);
        try {
          // Dispatch data to Redux
          const res = await addContact(dispatch, {
            email: emailFromURL,
            loanReason: loanReasonFromURL,
            ipAddress,
            userAgent: navigator.userAgent
          });
          // Send data to email phonexa
          sendDataBrevo(emailFromURL, "", "", [47], loanReasonFromURL);
          // check last step and navigator to next page
          const lastStep = handleLastStep(res?.contact);
          setShowLoading(false);
          lastStep && navigate(`/${lastStep}`);
        } catch (error) {
          setShowLoading(false);
          console.error("Error processing data:", error);
        }
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress, emailFromURL, loanReasonFromURL]);

  const handleInputChange = (e, newInputValue) => {
    if (newInputValue.includes("@")) {
      const [email, domain] = newInputValue.split("@");
      const filteredOptions = emailDomainsOptions
        .filter((option) => option.includes(domain))
        .map((option) => `${email}@${option}`);
      setEmailOptions(filteredOptions);
    } else {
      const email = newInputValue;
      const filteredOptions = emailDomainsOptions.map(
        (option) => `${email}@${option}`
      );
      setEmailOptions(filteredOptions);
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });

  const onSubmit = async (data) => {
    if (loadingButton) {
      return;
    }
    try {
      setLoadingButton(true);

      const res = await addContact(dispatch, {
        ...data,
        loanAmount: amount,
        ipAddress,
        userAgent: navigator.userAgent,
        aff_sub3: Cookies.get("_fbc"),
        aff_sub4: Cookies.get("_fbp")
      });
      if (res?.status === 200) {
        setLoadingButton(false);
        const convertedContact = convertContactFromResponse(
          res.data.data.contact
        );
        const lastStep = handleLastStep(convertedContact);
        navigate(`/${lastStep}`);
      } else {
        setError("email", {
          type: "error",
          message: res?.message
        });
        setLoadingButton(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoadingButton(false);
    }
  };

  return (
    <>
      {showLoading && <LoadingFull />}
      <div className="banner" id="banner">
        {openRateFee && (
          <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
        )}
        <div className="container">
          <Box
            className="banner-box"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isMobile ? "0" : "40px",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <Box
              sx={{
                flex: 1,
                maxWidth: isMobile ? "309px" : "auto",
                textAlign: "center",
                position: "relative",
                zIndex: "1"
              }}
            ></Box>
            <Box
              sx={{
                flex: 1,
                padding: isMobile ? "19px 14px 35px" : "19px 47px 20px",
                background: "#FFF",
                borderRadius: "4px"
              }}
            >
              <h2 className="title">
                Get Fast and Reliable Loans up to $5,000
              </h2>
              {/* FORM */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="amount"
                  defaultValue={amount}
                  control={control}
                  rules={{ required: "Loan amount is required!" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.amount}
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Loan Amount ($100 - $5,000)
                      </FormHelperText>
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setAmount(value);
                        }}
                        value={amount}
                        displayEmpty
                        MenuProps={{
                          ...MenuProps,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                          }
                        }}
                        sx={selectCustom}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        className={isSelectOpen ? "show" : ""}
                      >
                        {amountOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.amount && errors?.amount.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue={email}
                  rules={{
                    required: "Email is required!",
                    pattern: {
                      value:
                        /^[A-Z0-9._%+-]+@(gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|icloud\.com|aol\.com|live\.com|protonmail\.com|yandex\.com|proton\.me|msn\.com|comcast\.net|sbcglobal\.net|yandex\.ru|me\.com|att\.net|rediffmail\.com|ymail\.com|verizon\.net)$/i,
                      message: "Invalid email address"
                    }
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Your email address
                      </FormHelperText>
                      <Autocomplete
                        {...field}
                        freeSolo
                        id="email"
                        name="email"
                        onOpen={() => setOpenEmailOptions(true)}
                        onClose={() => setOpenEmailOptions(false)}
                        disableClearable
                        value={email}
                        options={emailOptions}
                        onChange={(e, option) => {
                          setEmail(option);
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleInputChange(event, newInputValue);
                          field.onChange(newInputValue);
                        }}
                        ListboxComponent="div"
                        ListboxProps={{ sx: ListBoxCustom }}
                        //PopperComponent={isMobile && "bottom"}
                        noOptionsText={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={email ? "" : "e.g. john123@gmail.com"}
                            size={isMobile ? "small" : "medium"}
                            sx={{
                              ...textFieldCustom
                            }}
                            className={
                              openEmailOptions && emailOptions.length > 0
                                ? "show"
                                : ""
                            }
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                ...textInputStyles,
                                color: "#B7B7B7"
                              }
                            }}
                            value={email}
                            error={!!errors?.email}
                            helperText={errors?.email && errors?.email?.message}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />

                <p className="tcpa">
                  By clicking <b>'Request Now'</b>, you agree to our{" "}
                  <Link to="/privacy">Privacy Policy</Link>,{" "}
                  <Link to="/terms-of-use">Terms</Link>
                  {", "}
                  <Link onClick={() => setOpenRateFee(!openRateFee)}>
                    Rate & Fees
                  </Link>{" "}
                  and receive special offers from us and our marketing partners
                  via email communication
                </p>
                <LoadingButton
                  endIcon={<></>}
                  // endIcon={<SendIcon />}
                  loading={loadingButton}
                  loadingPosition="end"
                  variant="contained"
                  size="large"
                  style={btnSubmitCustom}
                  onClick={() => {
                    handleSubmit(onSubmit);
                  }}
                  type="submit"
                >
                  <span>REQUEST NOW</span>
                </LoadingButton>
              </form>

              {/* FORM */}
              <div className="list-security">
                <div className="item">
                  <img src={DataProtected} alt="DataProtected" />
                  <p>DATA PROTECTED</p>
                </div>
                <div className="item">
                  <img src={SSL} alt="DataProtected" />
                  <p>ENCRYPTED DATA</p>
                </div>
                <div className="item">
                  <img src={Clear} alt="DataProtected" />
                  <p>CLEAR-CUT FORM</p>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Banner;
