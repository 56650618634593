import { 
  currentBankDurationOptionsMap,
  currentCompanyDurationOptionsMap,
} from "./const";
import {
  inHousePersonalLoanRequest,
  inHousePublicRequest,
  publicRequest,
} from "../requestMethods";

import Cookies from "js-cookie";
import axios from "axios";
import { convertDateFormat } from "./functions";
import dayjs from "dayjs";

export const getClickId = (utm_campaign) => {
  // Permate
  utm_campaign = utm_campaign ? utm_campaign.toLowerCase() : "";
  if (utm_campaign === "pm") {
    try {
      const pmClick = JSON.parse(Cookies.get("pm_click"));
      return pmClick.click;
    } catch {
      const urlParams = new URLSearchParams(
        sessionStorage.getItem("url_params")
      );
      return (
        urlParams.get("clickid") || urlParams.get("affiliate_clickid") || ""
      );
    }
  }
  // HasOffer
  if (
    utm_campaign === "ho" ||
    utm_campaign === "direct" ||
    utm_campaign === "ndirect"
  ) {
    const offerId =
      sessionStorage.getItem("offerid") || sessionStorage.getItem("offer-id");
    const HOcidKey = `tdl_default_${offerId}`;
    if (utm_campaign === "direct") {
      return sessionStorage.getItem(HOcidKey) || "";
    }
    const params = new URLSearchParams(sessionStorage.getItem("url_params"));
    if (utm_campaign === "ndirect" && params) {
      return sessionStorage.getItem("HOclickId") || params.get("clickid") || "";
    }
    return (
      sessionStorage.getItem(HOcidKey) || sessionStorage.getItem("HOclickId") || (params ? params.get("clickid") : "")
    );
  }
  // Voluum
  if (utm_campaign === "vl") {
    return Cookies.get("vl-cid") || "";
  }
  return "";
};

function convertOldDataOfBankAccountLengthMonth(value) {
  if (isNaN(value)) return currentBankDurationOptionsMap[value] ?? value;
  if (value instanceof Number) return value;
  return value;
}
function convertOldDataOfCurrentCompanyDuration(value) {
  if (isNaN(value)) return currentCompanyDurationOptionsMap[value] ?? value;
  if (value instanceof Number) return value;
  return value;
}

export const sendDataPhonexa = async (contactRedux) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const storedParams = new URLSearchParams(
        sessionStorage.getItem("url_params")
      );
      let bankAccountLengthMonths =
        contactRedux?.currentBankDuration ??
        contactRedux?.bankAccountLengthMonths;
      bankAccountLengthMonths = convertOldDataOfBankAccountLengthMonth(
        bankAccountLengthMonths
      );
      const data = {
        lead_id: sessionStorage.getItem("lead_id") ? contactRedux.lead_id : "",
        productId: "19",
        price: "0",
        loanAmount:
          contactRedux?.loanAmount ??
          contactRedux?.amount ??
          contactRedux?.loan_amount,
        workCompanyName:
          contactRedux?.workCompanyName ??
          contactRedux?.work_company_name ??
          contactRedux?.employerName,
        jobTitle: contactRedux?.jobTitle,
        activeMilitary:
          contactRedux?.activeMilitary ??
          (contactRedux.incomeType === "MILITARY" ? "YES" : "NO"),
        workTimeAtEmployer: convertOldDataOfCurrentCompanyDuration(
          contactRedux?.workTimeAtEmployer
        ),
        ssn: contactRedux?.ssn ?? contactRedux?.socialSecurityNumber,
        driversLicenseNumber:
          contactRedux?.driversLicenseNumber ?? contactRedux?.licenseID,
        driversLicenseState:
          contactRedux?.driversLicenseState ?? contactRedux?.issuingState,
        incomeType: contactRedux?.incomeType ?? contactRedux?.incomeSource,
        incomePaymentFrequency: contactRedux?.incomePaymentFrequency,
        incomeNetMonthly:
          contactRedux?.incomeNetMonthly ?? contactRedux?.monthlyIncome,
        incomeNextDate1: contactRedux?.incomeNextDate1,
        incomeNextDate2: contactRedux?.incomeNextDate2,
        bankDirectDeposit:
          contactRedux?.bankDirectDeposit ?? contactRedux?.getDirectDeposit,
        bankAba:
          contactRedux?.bankAba ??
          contactRedux?.routingNumber ??
          contactRedux?.bank_aba,
        bankName: contactRedux?.bankName,
        bankAccountNumber:
          contactRedux?.bankAccountNumber ?? contactRedux?.bankAccount,
        bankAccountType: contactRedux?.bankAccountType,
        firstName: contactRedux?.firstName,
        lastName: contactRedux?.lastName,
        dob:
          contactRedux?.dob &&
          dayjs(convertDateFormat(contactRedux?.dob)).format("YYYY-MM-DD"),
        address: contactRedux?.address ?? contactRedux?.streetAddress,
        addressLengthMonths: contactRedux?.addressLengthMonths,
        city: contactRedux?.city,
        state: contactRedux?.state,
        zip: contactRedux?.zip ?? contactRedux.zipCode,
        ownHome:
          contactRedux?.ownHome ??
          contactRedux.own_home ??
          contactRedux?.residenceOwnership,
        email: contactRedux?.email,
        homePhone: contactRedux?.homePhone ?? contactRedux?.phone,
        workPhone: contactRedux?.workPhone ?? contactRedux?.employerPhone,
        cellPhone: contactRedux?.cellPhone ?? contactRedux?.phone,
        userIp: contactRedux?.userIp ?? contactRedux?.user_ip,
        contactTime: contactRedux?.contactTime ?? contactRedux?.bestTimeContact,
        creditScore:
          contactRedux?.creditScore ?? contactRedux?.creditScoreRating,
        loanReason: contactRedux?.loanReason ?? contactRedux?.loan_reason,
        userAgent: contactRedux?.userAgent,
        debt_amount:
          contactRedux?.debt_amount ?? contactRedux?.unsecuredDebtAmount,
        time_at_residence:
          contactRedux?.time_at_residence ??
          contactRedux?.currentResidenceDuration,
        own_car: contactRedux?.own_car ?? contactRedux?.ownCar,
        source: sessionStorage.getItem("utm_medium") ?? "",
        webSiteUrl: window.location.protocol + "//" + window.location.hostname,
        utm_source: sessionStorage.getItem("utm_source") ?? "",
        sub_source: sessionStorage.getItem("sub_source") ?? "",
        platform: sessionStorage.getItem("utm_campaign") ?? "",
        "tPar[affiliateId]": sessionStorage.getItem("aff_id") ?? "",
        "tPar[affiliateSubId]": sessionStorage.getItem("offer_id") ?? "",
        aff_id: storedParams.get("pubs") ?? "",
        source_id: sessionStorage.getItem("offerid") ?? "",
        consentEmailSms: "YES",
        consentToFcra: "YES",
        trustedFormURL: sessionStorage.getItem("trustedFormURL") ?? "",
        bankAccountLengthMonths: bankAccountLengthMonths,
        confirm_pii: contactRedux?.ssn && contactRedux.ssn.split("-").join(""),
        aff_sub1: sessionStorage.getItem("utm_id") ?? "",
        aff_sub2: Cookies.get("vl-cid") ?? "",
        aff_sub3: Cookies.get("_fbc") ?? "",
        aff_sub4: Cookies.get("_fbp") ?? "",
        aff_sub5: sessionStorage.getItem("ad_id"),
        closeConnection: 1,
      };

      const testMode = sessionStorage.getItem("testMode") ?? "";
      const testSold = sessionStorage.getItem("testSold") ?? "";
      const clickId = getClickId(sessionStorage.getItem("utm_campaign"));

      if (testMode) data.testMode = testMode;
      if (testSold) data.testSold = testSold;
      if (clickId) data.clickid = clickId;

      return inHousePersonalLoanRequest
        .post("/send/v2", data)
        .then((response) => {
          resolve(response);
          try {
            axios
              .post(
                `${process.env.REACT_APP_INHOUSE_API_BASE_URL}/v1/fe/logs`,
                { pretext: "FE receive response:", text: response.data },
                { headers: { "content-type": "application/json" } }
              )
              .then((re) => {});
          } catch {}
        })
        .catch((error) => {
          const text = error.toJSON();
          try {
            axios
              .post(
                `${process.env.REACT_APP_INHOUSE_API_BASE_URL}/v1/fe/logs`,
                {
                  pretext: "FE receive error when request:",
                  text: text,
                  error_response: error.response?.data,
                  type: "error"
                },
                { headers: { "content-type": "application/json" } }
              )
              .then((re) => {});
          } catch {}
          throw error;
        });
    }, 0);
  });
};

export const checkPingResult = async (leadId, checkKey, utmSource) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return inHousePersonalLoanRequest
        .get(
          `/ping/v2?lead_id=${leadId}&checkKey=${checkKey}&utm_source=${utmSource}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          const text = error.toJSON();
          axios
              .post(
                `${process.env.REACT_APP_INHOUSE_API_BASE_URL}/v1/fe/logs`,
                { pretext: "FE receive response:", text: text },
                { headers: { "content-type": "application/json" } }
              ).then();
          throw error;
        });
    }, 0);
  });
};

export const sendDataBrevo = async (
  email,
  firstName,
  lastName,
  listIds,
  loanReason
) => {
  return new Promise((resolve) => {
    const data = {
      email: email,
      attributes: {
        FIRSTNAME: firstName,
        LASTNAME: lastName,
        LOANREASON: loanReason,
      },
      listIds: listIds,
      emailBlacklisted: false,
      smsBlacklisted: false,
      updateEnabled: false,
      smtpBlacklistSender: ["info@sendinblue.com"],
    };

    return publicRequest
      .post("/brevo", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

// UPDATE BREVO MAPPING STEP
export const updateDataBrevo = async (
  email,
  firstName,
  lastName,
  listIds,
  loanReason
) => {
  return new Promise((resolve) => {
    const data = {
      email: email,
      attributes: {
        FIRSTNAME: firstName,
        LASTNAME: lastName,
        LOANREASON: loanReason,
      },
      listIds: listIds,
      // emailBlacklisted: false,
      // smsBlacklisted: false,
      // updateEnabled: false,
      // smtpBlacklistSender: ["info@sendinblue.com"],
      // questionNumber
      // questionNumber: questionNumber
    };

    return publicRequest
      .put("/brevo/mapping-step", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataEdelivery = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      list_id: 1,
      email: email,
      fname: firstName,
      lname: lastName,
      phone: "3025445405",
    };

    return publicRequest
      .post("/phonexa/edelivery", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataMailjet = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      Email: email,
      Properties: {
        firstname: firstName,
        name: lastName,
      },
    };

    return publicRequest
      .post("/send-mail/mailjet", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataMyMarketing = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };

    return publicRequest
      .post("/send-mail/mymarketing", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};
export const searchFirstNameApi = async (firstName) => {
  if (firstName.length < 3) return [];
  try {
    const res = await inHousePublicRequest.get(
      `/personal_loan/suggest?first_name=${firstName}`
    );
    return res?.data?.data?.first_name;
  } catch (error) {}
};
export const searchLastNameApi = async (lastName) => {
  if (lastName.length < 3) return [];
  try {
    const res = await inHousePublicRequest.get(
      `/personal_loan/suggest?last_name=${lastName}`
    );
    return res?.data?.data?.last_name;
  } catch (error) {}
};
export const searchJobTitleApi = async (jobTitle) => {
  if (jobTitle.length < 3) return [];
  try {
    const res = await inHousePublicRequest.get(
      `/personal_loan/suggest?job_title=${jobTitle}`
    );
    return res?.data?.data?.job_title;
  } catch (error) {}
};
export const searchEmployerNameApi = async (employerName) => {
  if (employerName.length < 3) return [];
  try {
    const res = await inHousePublicRequest.get(
      `/personal_loan/suggest?employer=${employerName}`
    );
    return res?.data?.data?.employer;
  } catch (error) {}
};
