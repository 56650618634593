import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step15.scss";
import Progress from "../../../components/Progress/Progress";

import prevBtn from "../../../assets/images/prev-btn.png";
import step3Icon from "../../../assets/images/step-icon/step15-icon.png";
import checkedIcon from "../../../assets/images/checked.png";

import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { btnSubmitCustom } from "./style";
import { useForm } from "react-hook-form";
import { nextStepDelayTime } from "../../../utils/const";

const Step15 = () => {
  const currentBankDurationOptions = [
    "1 - 3 month",
    "3 - 6 months",
    "7 - 12 months",
    "1 - 2 year",
    "More than 2 years"
  ];
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentBankDuration, setCurrentBankDuration] = useState(
    contactRedux?.currentBankDuration ?? currentBankDurationOptions[4]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const [loadingButton, setLoadingButton] = useState(false);

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    if (loadingButton) {return}
    setLoadingButton(true);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      lead_id,
      currentBankDuration: currentBankDuration
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/apply/driver");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-15">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",

            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        >
          <Progress step={14} />
        </Box>
        <div
          className="step-main"
          // style={{ height: `calc(100vh - ${containerHeight}px)` }}
        >
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={step3Icon}
                alt=""
              />
            </Box>
            <h2 className="title">
              How long have you been at your current bank?
            </h2>

            <div className="form-control">
              <div className="list-btn-radio">
                {currentBankDurationOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      currentBankDuration === option ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setCurrentBankDuration(option);
                    }}
                  >
                    {option}
                    {currentBankDuration === option && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto",
                height: isMobile ? "45px" : "50px",
                display: "flex",
                gap: "10px"
              }}
            >
              <Link to="/apply/deposit">
                <img
                  src={prevBtn}
                  alt=""
                  style={{
                    width: isMobile ? "45px" : "50px",
                    height: isMobile ? "45px" : "50px"
                  }}
                />
              </Link>
              <LoadingButton
                endIcon={<></>}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                type="submit"
              >
                <span>Next step</span>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </Box>
      <Footer desc={false} />
    </Box>
  );
};

export default Step15;
