import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import LinearProgress from "@mui/material/LinearProgress";
import { generateRandomNumbers } from "../../utils/functions";

export const NewSteps = ({
  newSteps,
  newStepsStatus,
  setNewStepsStatus,
  setShowNewSteps,
  setShowProgress,
  callApiComplete
}) => {
  const isMobile = window.innerWidth < 768;
  const max = 100;
  const [counter, setCounter] = useState(0);
  const [i, setI] = useState(0);
  const timeStep = generateRandomNumbers();

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        if (counter < max) {
          setCounter((prevCounter) => prevCounter + 1);
        } else {
          setNewStepsStatus((prev) => {
            const newStatus = [...prev];
            for (let j = 0; j < newStatus.length; j++) {
              if (newStatus[j] === "active") {
                newStatus[j] = "completed";
                if (j + 1 < newStatus.length) {
                  newStatus[j + 1] = "active";
                  setCounter(0);
                } else {
                  setShowProgress(true);
                  setShowNewSteps(false);
                }
                setI(j + 1);
                break;
              }
            }
            return newStatus;
          });
        }
      },
      !callApiComplete ? (timeStep[i] * 1000) / max : 10
    );

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, timeStep, callApiComplete]);

  return (
    <Box className="box-new-step">
      <Box
        className="new-step"
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          display: isMobile ? "block" : "flex",
          marginBottom: "20px",
          alignItems: "center",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        }}
      >
        {newSteps.map((item, index) => (
          <Box
            className="new-step-item"
            key={index}
            sx={{
              display: "flex",
              flex: 1,
              padding: "15px 20px",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#F5F5F5"
              },
              backgroundColor:
                newStepsStatus[index] === "active" ? "#fff" : "#F5F5F5",
              gap: "15px",
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <Box
              className="new-step-item-icon"
              sx={{
                flex: 1,
                maxWidth: "26px",
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={item.icon} alt="" />
            </Box>
            <Box
              className="new-step-item-right"
              sx={{
                flex: 4
              }}
            >
              <Box
                className="new-step-item-name"
                sx={{
                  marginBottom: "5px",
                  fontSize: "14px",
                  fontWeight: "600"
                }}
              >
                {item.name}
              </Box>
              {newStepsStatus[index] === "active" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#F5F5F5",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#06D6A0"
                        }
                      }}
                      variant="determinate"
                      value={counter}
                    />
                  </Box>
                  {/* <Box sx={{ minWidth: 35 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(counter)}%`}</Typography>
                  </Box> */}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
