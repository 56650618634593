import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import fb from "../../assets/images/social/fb.png";
import link from "../../assets/images/social/link.png";
import tw from "../../assets/images/social/tw.png";
import ins from "../../assets/images/social/in.png";
import yt from "../../assets/images/social/yt.png";

import { Link as Scroll } from "react-scroll";

import { Box, Typography } from "@mui/material";

const menuOptions = [
  {
    title: "Contact us",
    path: "/contact-us"
  },
  {
    title: "Privacy Policy",
    path: "/privacy"
  },
  {
    title: "Disclaimer",
    path: "/disclaimer"
  },
  {
    title: "Blog",
    path: "https://hanfincal.com/news-page/"
  },
  {
    title: "Terms of Use",
    path: "/terms-of-use"
  },
  {
    title: "Do not sell my information",
    path: "/do-not-sell-information"
  },
  {
    title: "Credit authorization agreement",
    path: "/credit-authorization-agreement"
  },
  {
    title: "Unsubscribe",
    path: "/unsubscribe"
  }
];

const Footer = ({ desc = true }) => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const location = useLocation();
  const menuURL = location.pathname.split("/")[1];
  const menuItemStyle = {
    color: "#000",
    fontFamily: "Open Sans",
    fontSize: isMobile ? "12px" : "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",

    flex: isMobile ? "1 1 50%" : "1 1 20%"
  };
  return (
    <Box className="footer" id="footer">
      <Box
        sx={
          {
            // background: menuURL === "" ? "#F6F6F6" : "#EAE9E9"
          }
        }
      >
        <Box
          sx={{
            maxWidth: "1230px",
            margin: "0 auto",
            padding: isMobile ? "20px 20px 10px" : "33px 0 0 ",
            borderBottom: "1px solid #EEE"
          }}
        >
          {desc && (
            <Typography
              component="p"
              sx={{
                marginBottom: "18px",

                color: "#606060",
                textAlign: "justify",
                fontFamily: "Poppins",
                fontSize: isMobile ? "12px" : "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%"
              }}
            >
              The operator of this website is not a lender, or a representative,
              broker, agent of any lenders and we do not make any loan or credit
              decisions. This website does not constitute an offer or
              solicitation to lend. The role of this website is to submit the
              information you provide to a lender who has the whole rights of
              reviewing and approving for a loan. By submitting your
              information, you agree to allow participating lenders to verify
              your information and check your credit. Not all lenders can
              provide the maximum amount advertised. The time of cash transfer
              may vary between lenders and may depend on your individual
              financial institution. In some circumstances faxing may be
              required. Not all lenders from our system operate in all US
              states, and the states serviced by this website may change from
              time to time and without notice. If you have any questions about
              your loan, please contact your lеndеr directly. Short term cash
              loans are meant to address immediate cash needs and are not a
              long-term solution for financial problems. Residents of some
              states may not be eligible for a short term cash loan based upon
              lender requirements.
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px"
            }}
          >
            <Box
              className="logo"
              sx={{
                maxWidth: isMobile ? "108px" : "100%"
              }}
            >
              {menuURL === "" ? (
                <Scroll
                  className="menu-item"
                  to="navbar"
                  spy={true}
                  smooth={true}
                  duration={800}
                  offset={-120}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <img src={logo} alt="" />
                </Scroll>
              ) : (
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              )}
            </Box>
            {/* icon */}
            {!isMobile && (
              <Box
                className="certificate"
                sx={{
                  display: "flex",
                  gap: "20px"
                }}
              >
                <Box
                  className="ssl"
                  sx={{
                    maxWidth: isMobile ? "59px" : "100%"
                  }}
                >
                  <Link target="_blank" to="https://www.facebook.com/hanfincal" aria-label="facebook">
                    <img src={fb} alt="" />
                  </Link>
                </Box>
                <Box
                  className="secure"
                  sx={{
                    maxWidth: isMobile ? "36px" : "100%"
                  }}
                >
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/company/hanfincal/"
                    aria-label="linkedin"
                  >
                    <img src={link} alt="" />
                  </Link>
                </Box>
                <Box
                  className="secure"
                  sx={{
                    maxWidth: isMobile ? "36px" : "100%"
                  }}
                >
                  <Link target="_blank" to="https://twitter.com/HanFincal" aria-label="twitter">
                    <img src={tw} alt="" />
                  </Link>
                </Box>
                <Box
                  className="secure"
                  sx={{
                    maxWidth: isMobile ? "36px" : "100%"
                  }}
                >
                  <Link target="_blank" to="https://hanfincal.com/#" aria-label="instagram">
                    <img src={ins} alt="" />
                  </Link>
                </Box>
                <Box
                  className="secure"
                  sx={{
                    maxWidth: isMobile ? "36px" : "100%"
                  }}
                >
                  <Link target="_blank" to="https://hanfincal.com/#" aria-label="youtube">
                    <img src={yt} alt="" />
                  </Link>
                </Box>
              </Box>
            )}
            {/* icon */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: isMobile ? "12px 20px 0" : "33px 0 0"
          // background: "#EAE9E9"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gridGap: "15px 20px",
            gap: isMobile ? "5px 0" : "15px 20px",
            margin: isMobile ? "0 auto 18px" : "0 auto 33px",
            maxWidth: "1070px",
            padding: isMobile ? "0" : "0 15px",

            ".menu-item-ft:nth-of-type(even)": {
              textAlign: isMobile ? "right" : "unset"
            }
          }}
        >
          {menuURL === "" ? (
            <Scroll
              style={menuItemStyle}
              to="navbar"
              spy={true}
              smooth={true}
              duration={800}
              offset={-120}
            >
              Home Page
            </Scroll>
          ) : (
            <Link to="/" style={menuItemStyle}>
              Home Page
            </Link>
          )}
          {menuOptions.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              style={menuItemStyle}
              className="menu-item-ft"
            >
              {item.title}
            </Link>
          ))}

          {isMobile && (
            <Box
              className="certificate"
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px"
              }}
            >
              <Box
                className="ssl"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <Link target="_blank" to="https://www.facebook.com/hanfincal" aria-label="facebook">
                  <img src={fb} alt="" />
                </Link>
              </Box>
              <Box
                className="secure"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/hanfincal/"
                  aria-label="linkedin"
                >
                  <img src={link} alt="" />
                </Link>
              </Box>
              <Box
                className="secure"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <Link target="_blank" to="https://twitter.com/HanFincal" aria-label="twitter">
                  <img src={tw} alt="" />
                </Link>
              </Box>
              <Box
                className="secure"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <Link target="_blank" to="https://hanfincal.com/#" aria-label="instagram">
                  <img src={ins} alt="" />
                </Link>
              </Box>
              <Box
                className="secure"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <Link target="_blank" to="https://hanfincal.com/#" aria-label="youtube">
                  <img src={yt} alt="" />
                </Link>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            textAlign: "center",
            background: "#278BA2",
            padding: "10px 0",
            width: isMobile ? "calc(100% + 56px)" : "100%",
            marginLeft: isMobile ? "-28px" : "0"
          }}
        >
          <Typography
            component="p"
            sx={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: isMobile ? "12px" : "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal"
            }}
          >
            © Copyright 2022 HANFINCAL
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
