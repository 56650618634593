import React from "react";
import "./ReadyToApply.scss";
import { NextBtn } from "../Button/NextBtn/NextBtn";
import { Link as Scroll } from "react-scroll";
import { btnSubmitCustom } from "./style";
import { Box, Typography } from "@mui/material";
import readyImg from "../../assets/images/ready-img.jpg";

const ReadyToApply = () => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        padding: isMobile ? "0 28px 35px" : "0 0 47px"
      }}
    >
      <Box
        className="ready-to-apply"
        data-aos="fade-down"
        data-aos-offset="-170"
        sx={{
          maxWidth: "1041px",
          margin: isMobile ? "0 auto" : "0 auto",
          padding: isMobile ? "29 14px 35px" : "25px",

          display: "flex",
          justifyContent: "space-between",
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          background: "#fff"
        }}
      >
        <Box sx={{ flex: 1, display: isMobile ? "none" : "block" }}>
          <img src={readyImg} alt="" />
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: isMobile ? "14px 14px 20px" : "35px"
          }}
        >
          <Typography
            component="h3"
            sx={{
              marginBottom: "5px",

              color: "#278BA2",
              fontFamily: "Open Sans",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            READY TO REQUEST THE FUNDS YOU NEED?
          </Typography>

          <Typography
            component="p"
            sx={{
              display: "inline-block",
              marginBottom: isMobile ? "12px" : "21px",

              color: "#606060",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            No matter what you need the loan for, money will be in your account
            if the request is approved.
          </Typography>
          <Box>
            <Scroll
              className="menu-item"
              to="banner"
              spy={true}
              smooth={true}
              duration={800}
              offset={-200}
            >
              <NextBtn
                element="button"
                style={{
                  ...btnSubmitCustom,
                  maxWidth: isMobile ? "100%" : "390px"
                }}
                label="Request Now"
              />
            </Scroll>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReadyToApply;
