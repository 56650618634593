const isMobile = window.innerWidth < 768;

const commonStyles = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  lineHeight: "normal"
};

export const textInputStyles = {
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "14px" : "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: isMobile && "1.9"
};

export const btnSubmitCustom = {
  flex: 1,
  marginBottom: isMobile ? "0" : "20",
  width: "100%",
  height: isMobile ? "45px" : "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  backgroundColor: "#06D6A0",
  textAlign: "center",
  color: "#FFF",
  fontSize: isMobile ? "15px" : "16px",
  fontWeight: 700,
  textTransform: "uppercase",
  boxShadow: "unset",
  ...commonStyles
};

export const labelCustom = {
  margin: "0 0 3px",
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "12px" : "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
};

export const textFieldCustom = {
  // SET HEIGHT FIELD
  "& .MuiInputBase-root": {
    minHeight: isMobile && "45px"
  },

  // marginBottom: "13px",
  "&.show": {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px 8px 0 0"
    }
  },
  "& .MuiOutlinedInput-root": {
    ...textInputStyles,
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#B7B7B7"
    },
    "&:hover fieldset": {
      borderColor: "#B7B7B7"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B7B7B7",
      borderWidth: "1px"
    }
  }
};
