import React, { useEffect, useState } from "react";
import "./Progress.scss";
import { Box, Typography } from "@mui/material";

const Progress = ({ step, myRef }) => {
  const totalSteps = 24
  const windowWidth = window.innerWidth;
  const prevValue = (100 / totalSteps) * (step - 1);
  const currentValue = (100 / totalSteps) * step;

  const [progressValue, setProgressValue] = useState(prevValue);

  useEffect(() => {
    const startTime = Date.now();
    const duration = 1000; // Thời gian animation (1 giây)
    // const endTime = startTime + duration;

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        const percentage = (elapsedTime / duration) * 100;
        const interpolatedValue =
          prevValue + (currentValue - prevValue) * (percentage / 100);
        setProgressValue(interpolatedValue);
        requestAnimationFrame(updateProgress);
      } else {
        setProgressValue(currentValue);
      }
    };

    requestAnimationFrame(updateProgress);
  }, [prevValue, currentValue]);

  return (
    <Box className="progress " ref={myRef}>
      <Box
        sx={{
          textAlign: "right"
        }}
      >
        <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontFamily: "Poppins",
            fontSize: windowWidth > 768 ? "15px" : "10px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal"
          }}
        >
          {Math.round(progressValue)}%
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "5px",
          borderRadius: "20px",
          background: "#D9D9D9"
        }}
      >
        <Box
          style={{
            width: step === 1 ? `${"5%"}` : progressValue + "%",
            height: "100%",
            borderRadius: "20px",

            backgroundColor: "#06D6A0"
          }}
          className="progress-bar"
        ></Box>
      </Box>
    </Box>
  );
};

export default Progress;
