import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step6.scss";
import Progress from "../../../components/Progress/Progress";
import checkedIcon from "../../../assets/images/checked.png";
import prevBtn from "../../../assets/images/prev-btn.png";
import loanReasonIcon from "../../../assets/images/step-icon/step6-icon.png";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { btnSubmitCustom } from "./style";
import { nextStepDelayTime } from "../../../utils/const";

const Step6 = () => {
  const options = [
    "0 - 1 year",
    "1 - 3 years",
    "3 - 5 years",
    "7 - 10 years",
    "5 - 7 years",
    "over 10 years"
  ];
  // eslint-disable-next-line no-unused-vars
  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentResidenceDuration, setCurrentResidenceDuration] = useState(
    contactRedux?.currentResidenceDuration ?? options[3]
  );
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom
  const {
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async () => {
    if (loadingButton) {return}
    setLoadingButton(true);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      lead_id,
      time_at_residence: currentResidenceDuration
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);

        navigate("/apply/own_home");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-6">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",

            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        >
          <Progress step={6} />
        </Box>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={loanReasonIcon}
                alt=""
              />
            </Box>
            <h2 className="title">
              How long have you been living at your current residence?
            </h2>

            <div className="form-control">
              <div className="list-btn-radio">
                {options.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      currentResidenceDuration === option ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setCurrentResidenceDuration(option);
                    }}
                  >
                    {option}
                    {currentResidenceDuration === option && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>

              {currentResidenceDuration === "" && (
                <span style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "10px",
                  display: "block",
                  textAlign: "center"
                }} className="error">{errors.currentResidenceDuration}</span>
              )}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto",
                height: isMobile ? "45px" : "50px",
                display: "flex",
                gap: "10px"
              }}
            >
              <Link to="/apply/address">
                <img
                  src={prevBtn}
                  alt=""
                  style={{
                    width: isMobile ? "45px" : "50px",
                    height: isMobile ? "45px" : "50px"
                  }}
                />
              </Link>
              <LoadingButton
                endIcon={<></>}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>Next step</span>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </Box>
      <Footer desc={false} />
    </Box>
  );
};

export default Step6;
