export const bankAccountTypeOptions = [
  {
    value: "CHECKING",
    label: "Checking"
  },
  {
    value: "SAVING",
    label: "Savings"
  }
];
