import React from "react";
import { Link } from "react-router-dom";
import "./StepBtn.scss";
import { Box } from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import LoadingButton from "@mui/lab/LoadingButton";

export const StepBtn = ({ nextLink, prevLink, onSubmit, loading, backgroundColor }) => {
  return (
    <Box className="step-btn">
      {prevLink && (
        <Link className="prev-btn" to={prevLink}>
          <ArrowRightAltIcon />
        </Link>
      )}
      <>
        {/* <Link className="center-btn" to={nextLink} > */}
        <Link className="center-btn" to={nextLink} onClick={onSubmit}>
          {/* {loading ? (
            <LoadingButton loading={loading}></LoadingButton>
          ) : ( */}
            <span>Next step</span>
          {/* )} */}
        </Link>
        {/* <Link className="next-btn" to={nextLink} > */}
        <Link className="next-btn" to={nextLink} onClick={onSubmit}>
          <ArrowRightAltIcon />
        </Link>
      </>
    </Box>
  );
};
