import React from "react";
import { Link } from "react-router-dom";
import "../Button.scss";

export const NextBtn = ({ to, label, style, onClick, element, type }) => {
  if (element === "button") {
    return (
      <button
        style={style}
        className="btn next-btn hover-green"
        onClick={onClick}
        type={type && type}
      >
        {label}
      </button>
    );
  }

  return (
    <Link
      style={style}
      to={to}
      className="btn next-btn hover-green"
      onClick={onClick}
    >
      {label}
    </Link>
  );
};
