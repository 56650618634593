import React from "react";
import "./PopupStep.scss";
import { Box, Typography } from "@mui/material";

export const PopupStep = ({
  icon,
  title,
  email,
  buttonsDirection,
  buttons
}) => {
  return (
    <Box className="popup-step">
      <Box className="popup-step-content">
        <Box className="popup-step-icon">
          <img src={icon} alt="" />
        </Box>
        <Typography component="h3">{title}</Typography>
        <Typography component="h5">{email}</Typography>
        <Box className="btn-wrapper" sx={{ flexDirection: buttonsDirection }}>
          {buttons.map((button, index) => (
            <button key={index} onClick={button?.onClick}>
              {button?.title}
            </button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
