import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step18.scss";
import Progress from "../../../components/Progress/Progress";

import prevBtn from "../../../assets/images/prev-btn.png";

import LoadingButton from "@mui/lab/LoadingButton";

import { updateContact } from "../../../redux/apiCalls";

import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PatternFormat } from "react-number-format";

import { Box, FormControl, FormHelperText, TextField } from "@mui/material";

import step3Icon from "../../../assets/images/step-icon/step18-icon.png";

import {
  btnSubmitCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { nextStepDelayTime } from "../../../utils/const";

const Step18 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(
    contactRedux?.socialSecurityNumber ?? ""
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {...data, lead_id});

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/apply/debt");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-18">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",

            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        >
          <Progress step={17} />
        </Box>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={step3Icon}
                alt=""
              />
            </Box>
            <h2 className="title">Social Security Number</h2>
            <h5>Many lenders need SSN to consider a loan.</h5>
            <div className="form-control">
              {/* socialSecurityNumber */}
              <Controller
                name="socialSecurityNumber"
                control={control}
                defaultValue={socialSecurityNumber}
                rules={{
                  required: "Social Security Number is required!",
                  pattern: {
                    value: /^\d{3}-\d{2}-\d{4}$/,
                    message: "Invalid social security number format xxx-xx-xxxx"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      marginBottom: "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Your license ID
                    </FormHelperText>
                    <PatternFormat
                      customInput={TextField}
                      format="###-##-####"
                      //
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="socialSecurityNumber"
                      // autoComplete="socialSecurityNumber"
                      variant="outlined"
                      fullWidth
                      type="text"
                      error={!!errors?.socialSecurityNumber}
                      placeholder="e.g. 123 - 12 - 1234"
                      helperText={
                        errors?.socialSecurityNumber &&
                        errors?.socialSecurityNumber?.message
                      }
                      InputLabelProps={{
                        shrink: false,
                        style: textInputStyles
                      }}
                      value={socialSecurityNumber}
                      sx={textFieldCustom}
                      size={isMobile ? "small" : "medium"}
                      onChange={(e) => {
                        setSocialSecurityNumber(e.target.value);
                        field.onChange(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
              />
              {/* socialSecurityNumber */}

              <h5
                style={{
                  textAlign: "justify"
                }}
              >
                I authorize Leadtree Global and its Network Partners with whom I
                am matched to obtain my consumer report, credit profile, or
                other credit information associate with me from any consumer
                reporting agency in connection with my inquiry.
              </h5>
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto",
                height: isMobile ? "45px" : "50px",
                display: "flex",
                gap: "10px"
              }}
            >
              <Link to="/apply/own_car">
                <img
                  src={prevBtn}
                  alt=""
                  style={{
                    width: isMobile ? "45px" : "50px",
                    height: isMobile ? "45px" : "50px"
                  }}
                />
              </Link>
              <LoadingButton
                endIcon={<></>}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>Next step</span>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </Box>
      <Footer desc={false} />
    </Box>
  );
};

export default Step18;
