import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./BankType.scss";

import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import checkedIcon from "../../../assets/images/checked.png";
import BankTypeIcon from "../../../assets/images/step-icon/banktype-icon.png";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { bankAccountTypeOptions } from "../../../utils/options";
import { PopupStep } from "../../../components/PopupStep/PopupStep";
import questionIcon from "../../../assets/images/step-icon/question.png";
import { nextStepDelayTime } from "../../../utils/const";

const BankType = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [bankAccountType, setBankAccountType] = useState(
    contactRedux?.bankAccountType ?? ""
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async () => {
    if (!bankAccountType) {
      setError("bankAccountType", {
        type: "invalid",
        message: "Please select one option."
      });
      return;
    }
    
    setLoadingButton(!loadingButton);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 19,
      lead_id,
      bankAccountType
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/banktime");
      }, nextStepDelayTime);
    }
  };

  return (
    <>
      {openPopup && bankAccountType === "Saving" && (
        <PopupStep
          icon={questionIcon}
          title="Did you mean 'Checking' as Bank account type?"
          buttonsDirection="column"
          buttons={[
            {
              title: "Change to “Checking”",
              onClick: async () => {
                setBankAccountType("Checking");
                onSubmit("Checking");
              }
            },
            {
              title: "Continue with selection",
              onClick: async () => {
                setBankAccountType("Saving");
                onSubmit("Saving");
              }
            }
          ]}
        />
      )}
      <Box className="BankType-page">
        <Navbar />
        <ScrollToTop />
        <Box
          sx={{
            width: "100%",
            maxWidth: "700px",
            margin: "20px auto"
          }}
        >
          <div className="step-main">
            <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  width: "100%",

                  margin: "10px auto 30px",
                  padding: isMobile && "0 15px"
                }}
              >
                <Progress step={18} />
              </Box>
              <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
                <img
                  style={{
                    maxWidth: isMobile && "42px"
                  }}
                  src={BankTypeIcon}
                  alt=""
                />
              </Box>
              <h2 className="title">Bank Account Type</h2>
              <h5>
                Banks are significantly more likely to fund checking accounts
                than savings accounts.
              </h5>

              <div className="form-control">
                <div className="list-btn-radio">
                  {bankAccountTypeOptions.map((option, index) => (
                    <button
                      key={index}
                      className={`btn-radio ${
                        bankAccountType === option.value ? "active" : ""
                      }`}
                      onClick={(e) => {
                        setBankAccountType(option.value);
                        clearErrors("bankAccountType");
                        // IF OPTION "Saving"
                        option.value === "Saving" && e.preventDefault();
                        option.value === "Saving" && setOpenPopup(true);
                      }}
                    >
                      {option.label}
                      {bankAccountType === option.value && (
                        <img
                          className="checked-icon"
                          src={checkedIcon}
                          alt=""
                        />
                      )}
                    </button>
                  ))}
                </div>

                {errors?.bankAccountType && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "10px",
                      display: "block",
                      textAlign: "center"
                    }}
                    className="error"
                  >
                    {errors.bankAccountType?.message}
                  </span>
                )}
              </div>

              <Box
                sx={{
                  maxWidth: "474px",
                  margin: "0 auto"
                }}
              >
                <StepBtn
                  prevLink="/aba"
                  onSubmit={handleSubmit(onSubmit)}
                  loading={loadingButton}
                />
              </Box>
            </form>
          </div>
        </Box>
        <Footer desc={false} />
      </Box>
    </>
  );
};

export default BankType;
