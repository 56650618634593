import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./RequestCash.scss";
import Progress from "../../../components/Progress/Progress";

import RequestCashIcon from "../../../assets/images/step-icon/requestcash-icon.png";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";
// CUSTOM STYLE

const RequestCash = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  return (
    <Box className="RequestCash-page">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form">
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={27} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={RequestCashIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Submit Loan Request</h2>
            <h5>
              It’s all set up! Just press the “Request Cash” button to submit
              your loan request!
            </h5>
            <div className="form-control">
              <Box sx={{ textAlign: "center", margin: "0 0 20px" }}>
                <span
                  style={{
                    color: "#606060",
                    textAlign: "justify",
                    fontFamily: "Open Sans",
                    fontSize: isMobile ? "10px" : "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal"
                  }}
                >
                  By clicking “Request Cash” you affirm that you have read,
                  understand, and agree to the Disclaimer, Privacy Policy,
                  Credit Authorization, Responsible Lending, Marketing
                  Practices, Rates & Fees and Terms of Use. Your click is your
                  electronic signature, and you authorize us to share your
                  information with lenders and third party marketers and
                  partners we contact with. By clicking “Request Cash”, you give
                  your written consent to 1) receive information and marketing
                  e-mail messages and notifications from us and third party
                  marketers we contract with 2) receive SMS/text messages and
                  autodialed or prerecorded calls from lenders and third party
                  marketers and partners on the telephone number provided in the
                  form (even if your number can be found in Do-Not-Call-Lists of
                  any kind). A list of such partners can be found here. In case
                  you do not wish to receive this kind of information by
                  messages, please text “STOP” to 3103400791. I understand that
                  my consent is not required to obtain a loan.
                </span>
              </Box>
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <Button
                onClick={() => navigate("/mapping-lender")}
                sx={{
                  borderRadius: "8px",
                  height: "42px",
                  width: "100%",
                  margin: "0 auto",
                  display: "block",
                  backgroundColor: "#FCA905",

                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: isMobile ? "14px" : "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",

                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#0060df",
                    opacity: "0.9"
                  }
                }}
              >
                Request Cash
              </Button>
            </Box>
          </form>
        </div>
      </Box>

      <Footer desc={false} />
    </Box>
  );
};

export default RequestCash;
