import React from "react";
import "./RejectPage.scss";

const RejectPage = () => {
  return (
    <div className="reject-page">
      <h1>Sorry, we were unable to connect you with a lender</h1>
    </div>
  );
};

export default RejectPage;
