const isMobile = window.innerWidth < 768;

const commonStyles = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  lineHeight: "normal"
};

export const btnSubmitCustom = {
  flex: 1,
  marginBottom: isMobile ? "0" : "20",
  width: "100%",
  height: isMobile ? "45px" : "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  backgroundColor: "#06D6A0",
  textAlign: "center",
  color: "#FFF",
  fontSize: isMobile ? "15px" : "16px",
  fontWeight: 700,
  textTransform: "uppercase",
  boxShadow: "unset",
  ...commonStyles
};
