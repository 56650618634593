import React, { useRef, useEffect } from "react";
import "./RatesFee.scss";
import close from "../../../assets/images/close1.png";

export const RatesFee = ({ openRateFee, setOpenRateFee }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenRateFee(false);
      }
    };

    if (openRateFee) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openRateFee, setOpenRateFee]);

  return (
    <div className={`rates-free ${openRateFee ? "open" : ""}`}>
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Rates & Fee</h2>
            <p className="text">
              <b>Hanfincal.com</b> provides free-of-charge service to
              help borrowers like you connect with reliable lenders to solve
              your financial needs. We do not review, approve or make any
              decisions related to your loan requests or approvals, you have to
              work with your lenders regarding rates, APR, lending policy, fees
              or any other rules. Each state may have different local
              regulations to proceed with your loan request, we only try our
              best to provide you with as much information as we can, including
              how it works and some FAQs.{" "}
            </p>
            <p className="text">
              It is important to read and understand all the agreement and
              related information, including the fees and APR, as well as the
              terms of repayment and loan renewal options, if your request is
              approved by the lender. If there are any terms or conditions you
              need to clarify, please check with your lenders to address all
              questions, we only can help you with relevant questions related to
              our service.{" "}
            </p>
            <p className="text">
              The most crucial thing that you should comprehend as a borrower is
              an implication of making late installments on your credit.
              Although every lender whom we work with has its own particular
              terms and conditions for the advances they offer, there are some
              late-installment repercussions that you ought to know before
              entering into a loan contract with the lender.
            </p>
            <p className="text">
              <b>Hanfincal.com</b> is a free-of-charge platform which
              collects your personal details to submit for the lenders to review
              your loan requests. We do not relate to the reviewing process or
              address your loan-related questions, please contact your financial
              providers directly to clarify all the information. We try our best
              to connect you with the most suitable lender, however the approval
              decision will be fully determined by the lender.{" "}
            </p>
            <p className="text">
              By reading Privacy Policy and Terms of Use, you understand and
              consent that this Site collects personal details and shares with
              its third-party partners. Also, the links of third-party websites
              are presented on this Site. For any loan reasons, you should
              connect with a consultation specialist before deciding your loan.{" "}
            </p>
          </div>
          <button
            className="close"
            onClick={() => setOpenRateFee(!openRateFee)}
          >
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
