import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Agreement.scss";



const Agreement = () => {
  
  return (
    <div className="credit-authorization-agreement">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Credit Authorization Agreement</h2>
          By submitting a debt solution loan request on this site, you understand, agree, and authorize that:
          <ol class="statement-list">
            <li>a. you are submitting a request for a loan and this request is a 
              business transaction initiated by you;</li>
            <li>b. your request and all information in it may be shown to multiple
              lenders, lending partners, and marketing partners in our network or in
              other financial networks (together “Partners*”) in real time or over
              time so that one or more of them can decide whether to offer you a loan
              or to otherwise advertise their loan or other financial products to you;</li>
            <li>c. you are providing written instruction under the Fair Credit Reporting
              Act and other applicable laws for Liftup Capital, Inc. and its
              partners*, to obtain consumer credit reporting and other related
              information about you from one or more consumer reporting agencies
              (including, but not limited to TransUnion, Experian and Equifax) for the
              purposes of verifying your identity, determining whether to offer you a
              loan, determining how much debt you currently have in order to determine
              your debt-to-income ratio, and to make other prequalification or credit
              decisions;</li>
            <li>d. many users of this site do not qualify for the loan types or amounts
              they request;</li>
            <li>e. some lenders may only offer loans in an amount below the amount your
              requested;</li>
            <li>f. any loan terms you may be offered are determined solely by your
              lender and not by us; and</li>
            <li>g. you will closely review your loan offer(s) and research various loan
              options and alternatives available to you.</li>
          </ol>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Agreement;
