import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./DriverID.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import driverIDIcon from "../../../assets/images/step-icon/driverId-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import { Autocomplete, Box, FormHelperText, TextField } from "@mui/material";
import { FormControl } from "@mui/material";

// CUSTOM STYLE

import {
  ListBoxCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { issuingStateOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";

const DriverID = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [openIssuingState, setOpenIssuingState] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [licenseID, setLicenseID] = useState(contactRedux?.driversLicenseNumber ?? contactRedux?.licenseID ?? "");
  const [issuingState, setIssuingState] = useState(
    issuingStateOptions.filter(
      (option) =>
        option.value === (contactRedux?.driversLicenseState)
    )[0]?.label ?? issuingStateOptions[0].label
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    data.issuingState = issuingStateOptions.filter(
      (option) => option.label === issuingState
    )[0]?.value;

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispath, contactRedux?._id, {
      step: 22,
      lead_id,
      driversLicenseNumber: licenseID,
      driversLicenseState: data.issuingState
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/owncar");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="DriverID-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div
          className="step-main"
          // style={{ height: `calc(100vh - ${containerHeight}px)` }}
        >
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={21} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={driverIDIcon}
                alt=""
              />
            </Box>
            <h2 className="title">
              What Is Your Driver’s License/State ID Number?
            </h2>
            <h5>
              Your driver’s license number is used to verify your identity and
              prevent fraud.
            </h5>
            <div className="form-control">
              {/* licenseID */}
              <Controller
                name="licenseID"
                control={control}
                defaultValue={licenseID}
                rules={{
                  required: "LicenseID is required!",
                  minLength: {
                    value: 2,
                    message: "Please enter at least 2 characters"
                  },
                  maxLength: {
                    value: 25,
                    message: "LicenseID must be 10 characters long"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{ ...formControlCustom, marginBottom: "7px" }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Your license ID
                    </FormHelperText>
                    <TextField
                      {...field}
                      inputRef={ref}
                      id="licenseID"
                      size={isMobile ? "small" : "medium"}
                      sx={textFieldCustom}
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      variant="outlined"
                      fullWidth
                      type="text"
                      error={!!errors?.licenseID}
                      label={licenseID ? " " : "1 to 19 characters"}
                      helperText={
                        errors?.licenseID && errors?.licenseID?.message
                      }
                      inputProps={{
                        maxLength: 25,
                        onInput: (e) => {
                          const value = e.target.value;
                          // const value = e.target.value.replace(/[^0-9]/g, "");
                          setLicenseID(value);
                        }
                      }}
                      value={licenseID}
                    />
                  </FormControl>
                )}
              />
              {/* licenseID */}
              {/* issuingState */}
              <Controller
                name="issuingState"
                control={control}
                defaultValue={issuingState}
                rules={{
                  required: "FirstName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "20px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Issuing state
                    </FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="issuingState"
                      name="issuingState"
                      onOpen={() => setOpenIssuingState(true)}
                      onClose={() => setOpenIssuingState(false)}
                      disableClearable
                      options={issuingStateOptions.map((option) => option)}
                      onChange={(e, option) => {
                        setIssuingState(option.label);
                      }}
                      onInputChange={(event, newInputValue) => {
                        field.onChange(newInputValue);
                        setIssuingState(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openIssuingState && issuingStateOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: textInputStyles
                          }}
                          error={!!errors?.issuingState}
                          helperText={
                            errors?.issuingState &&
                            errors?.issuingState?.message
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              // <KeyboardArrowDownIcon
                              //   sx={{
                              //     transition: "all 0.5s ease",
                              //     transform: openIssuingState
                              //       ? "rotate(180deg)"
                              //       : "rotate(0deg)"
                              //   }}
                              // />
                              <ArrowDropDownIcon
                                sx={{
                                  transition: "all 0.5s ease",
                                  transform: openIssuingState
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)"
                                }}
                              />
                            )
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* issuingState */}
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink="/bankaccount"
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
        </div>
      </Box>

      <Footer desc={false} />
    </Box>
  );
};

export default DriverID;
