import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step13.scss";
import { bankAccountTypeOptions } from "./options";
import Progress from "../../../components/Progress/Progress";

import prevBtn from "../../../assets/images/prev-btn.png";

import loanReasonIcon from "../../../assets/images/step-icon/step13-icon.png";
import LoadingButton from "@mui/lab/LoadingButton";

import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormHelperText,
  TextField
} from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  MenuProps,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  menuItemCustom,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
// CUSTOM STYLE
import axios from "axios";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { nextStepDelayTime } from "../../../utils/const";

const Step13 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [openBankAccountType, setOpenBankAccountType] = useState(false);
  const [showCircular, setShowCircular] = useState(false);
  // const [showCard, setShowCard] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [routingNumberError, setRoutingNumberError] = useState([]);

  const [routingNumber, setRoutingNumber] = useState(
    contactRedux?.routingNumber ?? ""
  );
  const [bankName, setBankName] = useState(contactRedux?.bankName ?? "");
  const [bankAccount, setBankAccount] = useState(
    contactRedux?.bankAccount ?? ""
  );

  const [bankAccountType, setBankAccountType] = useState(
    contactRedux?.bankAccountType ?? "CHECKING"
  );

  const checkBankInfo = async (routingNumber) => {
    setShowCircular(!showCircular);
    const options = {
      method: "GET",
      url: `https://routing-number-bank-lookup.p.rapidapi.com/api/v1/${routingNumber}`,
      params: {
        format: "json",
        paymentType: "ach"
      },
      headers: {
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_KEY,
        "X-RapidAPI-Host": "routing-number-bank-lookup.p.rapidapi.com"
      }
    };

    try {
      const response = await axios.request(options);

      setShowCircular(false);

      if (response?.data[0]?.status === "success") {
        const name = response?.data[0]?.data?.name;
        setBankName(name);
        setValue("bankName", name);
        setRoutingNumberError("");
        clearErrors("routingNumber");
      } else {
        setBankName("");
        setValue("bankName", "");
        setRoutingNumberError({
          name: "routingNumber",
          type: "exits",
          message: "Invalid Routing Number!"
        });
        setError("routingNumber", {
          type: "exits",
          message: "Invalid Routing Number!"
        });
      }
    } catch (error) {
      console.error(error);
      setShowCircular(false);
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue
  } = useForm();

  const onSubmit = async (data) => {
    if (routingNumberError?.name) {
      setError(routingNumberError?.name, {
        type: routingNumberError?.type,
        message: routingNumberError?.message
      });
      return;
    }

    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {...data, lead_id});

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/apply/deposit");
      }, nextStepDelayTime);
    }
  };
  return (
    <Box className="step-13">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",

            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        >
          <Progress step={12} />
        </Box>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={loanReasonIcon}
                alt=""
              />
            </Box>
            <h2 className="title">
              Hey {contactRedux?.firstName}!<br /> You are at the final steps
            </h2>
            <h5>
              By providing the bank details, lenders might review your request
              with high acceptance rate!
            </h5>
            <div className="form-control">
              {/* routingNumber */}
              <Controller
                name="routingNumber"
                control={control}
                defaultValue={routingNumber}
                rules={{
                  required: "Routing Number is required!",
                  validate: (value) => {
                    if (!/^\d{9}$/.test(value)) {
                      return "Routing Number must be a 9-digit number.";
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "7px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      ABA/Routing Number
                    </FormHelperText>
                    <TextField
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="routingNumber"
                      // autoComplete="routingNumber"
                      variant="outlined"
                      fullWidth
                      error={!!errors?.routingNumber}
                      label={routingNumber ? "" : "e.g. 026009593"}
                      helperText={
                        errors?.routingNumber && errors?.routingNumber?.message
                      }
                      inputProps={{
                        maxLength: 9,
                        onInput: (e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          field.onChange(value); // Update the value in the Controller
                          setRoutingNumber(value); // Update the state
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value.length === 9) {
                          checkBankInfo(e.target.value);
                        }
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      value={field.value} // Use field.value to reflect changes
                      size={isMobile ? "small" : "medium"}
                      sx={textFieldCustom}
                      InputProps={{
                        endAdornment: showCircular ? (
                          <CircularProgress
                            size={windowWidth > 768 ? 20 : 15}
                            color="inherit"
                          />
                        ) : null
                      }}
                    />
                  </FormControl>
                )}
              />

              {/* routingNumber */}
              {/* <button
                className="btn-show-example"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCard(!showCard);
                }}
              >
                Show Example{" "}
                <KeyboardArrowDownIcon
                  className={`icon-down  + ${!!showCard && "up"}`}
                />
              </button>
              {showCard && <img src={bank} alt="" />} */}
              {/* bankName */}
              <Controller
                name="bankName"
                control={control}
                defaultValue={bankName}
                rules={{
                  required: "Bank Name is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "7px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>Bank name</FormHelperText>
                    <TextField
                      {...field}
                      inputRef={ref}
                      id="bankName"
                      sx={textFieldCustom}
                      size={isMobile ? "small" : "medium"}
                      // autoComplete="bankName"
                      variant="outlined"
                      fullWidth
                      type="text"
                      error={!!errors?.bankName}
                      emptylabel="Bank Name"
                      label={bankName ? "" : "e.g. Bank Of America"}
                      helperText={errors?.bankName && errors?.bankName?.message}
                      inputProps={{
                        onInput: (e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setBankName(value);
                        }
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      // onChange={(e) => setBankName(e.target.value)}
                      value={bankName}
                    />
                  </FormControl>
                )}
              />
              {/* bankName */}
              {/* bankAccount */}
              <Controller
                name="bankAccount"
                control={control}
                defaultValue={bankAccount}
                rules={{
                  required: "Bank Account is required!",
                  minLength: {
                    value: 4,
                    message: "Please enter at least 4 digits"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "7px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Bank account
                    </FormHelperText>
                    <TextField
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="bankAccount"
                      size={isMobile ? "small" : "medium"}
                      sx={textFieldCustom}
                      // autoComplete="bankAccount"
                      variant="outlined"
                      fullWidth
                      error={!!errors?.bankAccount}
                      emptylabel="Bank Account"
                      label={bankAccount ? "" : "4 to 30 digits"}
                      helperText={
                        errors?.bankAccount && errors?.bankAccount?.message
                      }
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      inputProps={{
                        minLength: 4,
                        maxLength: 24,
                        onInput: (e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setBankAccount(e.target.value);
                        }
                      }}
                      value={bankAccount}
                    />
                  </FormControl>
                )}
              />
              {/* bankAccount */}
              {/* bankAccountType */}
              <Controller
                name="bankAccountType"
                defaultValue={bankAccountType}
                control={control}
                rules={{ required: "Bank Account Type is required!" }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      ...formControlCustom,
                      marginBottom: "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Type Of Bank Account
                    </FormHelperText>
                    <Select
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setBankAccountType(value);
                      }}
                      value={bankAccountType}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={selectCustom}
                      onClose={() => {
                        setOpenBankAccountType(false);
                      }}
                      onOpen={() => {
                        setOpenBankAccountType(true);
                      }}
                      className={openBankAccountType ? "show" : ""}
                    >
                      {bankAccountTypeOptions.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                          sx={menuItemCustom}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>
                      {errors?.bankAccountType &&
                        errors?.bankAccountType.message}
                    </FormHelperText> */}
                  </FormControl>
                )}
              />
              {/* bankAccountType */}
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto",
                height: isMobile ? "45px" : "50px",
                display: "flex",
                gap: "10px"
              }}
            >
              <Link to="/apply/score">
                <img
                  src={prevBtn}
                  alt=""
                  style={{
                    width: isMobile ? "45px" : "50px",
                    height: isMobile ? "45px" : "50px"
                  }}
                />
              </Link>
              <LoadingButton
                endIcon={<></>}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>Next step</span>
              </LoadingButton>
            </Box>
          </form>
        </div>
      </Box>

      <Footer desc={false} />
    </Box>
  );
};

export default Step13;
