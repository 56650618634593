import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

import Logo from "../../assets/images/logo.png";
import Menu from "../../assets/images/menu.png";

import "./Navbar.scss";
import { RatesFee } from "../popups/RatesFee/RatesFee";
import { FAQs } from "../popups/FAQs/FAQs";
import { HowItWorksPopup } from "../popups/HowItWorksPopup/HowItWorksPopup";
import { Box } from "@mui/material";

const Navbar = () => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [openMenu, setOpenMenu] = useState(false);
  const [openRateFee, setOpenRateFee] = useState(false);
  const [openFAQs, setOpenFAQs] = useState(false);
  const [openHowItWorks, setOpenHowItWorks] = useState(false);

  const location = useLocation();
  const subURL = location.pathname.split("/")[1];
  const subURL1 = location.pathname.split("/")[2];

  // useEffect(() => {
  //   if (subURL === "start" && contactRedux) {
  //     // CHECK STEP
  //     const lastStep = checkLastStep(contactRedux);
  //     const stepsRequiringPayFrequency = [
  //       "company_time",
  //       "job",
  //       "score",
  //       "bank",
  //       "deposit",
  //       "bank_time",
  //       "driver",
  //       "own_car",
  //       "ssn",
  //       "debt"
  //     ];
  //     if (stepsRequiringPayFrequency.includes(lastStep)) {
  //       // navigate("/nextpaydatesort");
  //     }
  //   }
  // });

  return (
    <nav
      className={`navbar ${
        subURL === "congratulations" && "congratulations-nav"
      }`}
      id="navbar"
    >
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      {openFAQs && <FAQs openFAQs={openFAQs} setOpenFAQs={setOpenFAQs} />}

      {openHowItWorks && (
        <HowItWorksPopup
          openHowItWorks={openHowItWorks}
          setOpenHowItWorks={setOpenHowItWorks}
        />
      )}
      <Box
        className="container"
        sx={{
          "::after": {
            display: subURL === "apply" && !subURL1 ? "block" : "none"
          }
        }}
      >
        {isMobile && (
          <div className="logo">
            <Link to="/" aria-label="logo">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
        )}
        {!isMobile && (
          <div className="logo">
            <Link to="/" aria-label="logo">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
        )}
        <button
          id="show-menu"
          aria-label="toggle menu"
          onClick={() => setOpenMenu(!openMenu)}
          style={
            openMenu
              ? { transform: "rotate(90deg)" }
              : { transform: "rotate(0)" }
          }
        >
          <img src={Menu} alt="" />
        </button>

        <ul
          className={openMenu ? "menu show" : "menu"}
          style={openMenu ? { top: "66px" } : { top: "-100%" }}
        >
          {!isMobile && (
            <li>
              <Link
                to="https://hanfincal.com/personal-finance-page/?active=personal-finance-all"
                className="menu-item"
              >
                Blog
              </Link>
            </li>
          )}
          <li>
            {subURL === "" ? (
              <Scroll
                className="menu-item"
                to="howItWork"
                spy={true}
                smooth={true}
                duration={800}
                offset={-120}
                onClick={() => setOpenMenu(!openMenu)}
              >
                How it works
              </Scroll>
            ) : (
              <Link
                onClick={() => {
                  setOpenMenu(!openMenu);
                  setOpenHowItWorks(!openHowItWorks);
                }}
                className="menu-item"
              >
                How it works
              </Link>
            )}
          </li>

          <li onClick={() => setOpenMenu(!openMenu)}>
            <Link
              onClick={() => {
                setOpenMenu(!openMenu);
                setOpenRateFee(!openRateFee);
              }}
              className="menu-item"
            >
              Rates & Fees
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setOpenMenu(!openMenu);
                setOpenFAQs(!openFAQs);
              }}
              className="menu-item"
            >
              FAQs
            </Link>
          </li>
        </ul>
      </Box>
    </nav>
  );
};

export default Navbar;
