import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import checkedIcon from "../../../assets/images/checked.png";
import incomesourceIcon from "../../../assets/images/step-icon/incomesource-icon.png";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { incomeSourceOptions } from "../../../utils/options";
import "./IncomeSource.scss";
import { nextStepDelayTime } from "../../../utils/const";

const IncomeSource = () => {
  const [loadingButton, setLoadingButton] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [incomeSource, setIncomeSource] = useState(
    contactRedux?.incomeType ?? contactRedux?.incomeSource ?? ""
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async () => {
    if (!incomeSource) {
      setError("incomeSource", {
        type: "error",
        message: "Please choose an answer"
      });
      return;
    }

    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 9,
      lead_id,
      incomeType:
        incomeSource === "MILITARY"
          ? "SELF_EMPLOYMENT"
          : incomeSource,
      activeMilitary: incomeSource === "MILITARY" ? "YES" : "NO"
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/emptime");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-8">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={8} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={incomesourceIcon}
                alt=""
              />
            </Box>
            <h2 className="title">What is your source of income?</h2>
            <h5>Are you currently employed or receiving benefits?</h5>

            <div className="form-control">
              <div className="list-btn-radio">
                {incomeSourceOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      incomeSource === option.value ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setIncomeSource(option.value);
                      clearErrors("incomeSource");
                    }}
                  >
                    {option.label}
                    {incomeSource === option.value && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>

              {errors?.incomeSource && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                    display: "block",
                    textAlign: "center"
                  }}
                  className="error"
                >
                  {errors?.incomeSource?.message}
                </span>
              )}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink="/ownhome"
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
        </div>
      </Box>
      <Footer desc={false} />
    </Box>
  );
};

export default IncomeSource;
