import React from "react";

import img1 from "../../assets/images/whyChooseUS/img1.png";
import img2 from "../../assets/images/whyChooseUS/img2.png";
import img3 from "../../assets/images/whyChooseUS/img3.png";

import { Box, Card, Typography } from "@mui/material";

const isMobile = window.innerWidth < 768;

const cardOptions = [
  {
    icon: img1,
    title: "Easy to request",
    desc: "Online request is an ideal option for your to request everywhere."
  },
  {
    icon: img2,
    title: "All Credit Types Welcome",
    desc: "Your credit histories might not be good but you still have chances based on lenders’ reviews."
  },
  {
    icon: img3,
    title: "Secure for you",
    desc: "We prioritize the data security by applying the latest technology to protect your information."
  }
];

const titleStyle = {
  marginBottom: isMobile ? "25px" : "10px",
  position: "relative",

  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "20px" : "25px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",

  span: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "50px",
    height: "4px",
    backgroundColor: "#06D6A0"
  }
};

const ListCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "20px"
      }}
    >
      {cardOptions.map((item, index) => (
        <Card
          key={index}
          sx={{
            flex: 1,
            display: "flex",
            borderRadius: "10px",
            boxShadow: "none"
          }}
        >
          {/* icon left */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              background: "#E1F0F3",
              borderRadius: "10px"
            }}
          >
            <img
              style={{
                maxWidth: "37px"
              }}
              src={item.icon}
              alt=""
            />
          </Box>
          {/* icon left */}
          <Box
            sx={{
              flex: 3,
              padding: "25px 13px 22px"
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: "#606060",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                marginBottom: "5px"
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="p"
              sx={{
                display: "inline-block",
                color: "#606060",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal"
              }}
            >
              {item.desc}
            </Typography>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

const WhyChooseUS = () => {
  return (
    <Box
      className="why-choose-us"
      sx={{
        backgroundColor: "#F5F5F5"
      }}
    >
      <Box
        className="why-choose-us"
        sx={{
          maxWidth: "1100px",
          margin: isMobile ? "0 auto" : "0 auto 0",
          padding: isMobile ? "0 28px 25px" : "0 0 47px"
        }}
      >
        <Box
          sx={{
            padding: isMobile ? "30px 0" : "30px 44px 40px",
            borderRadius: "8px"
          }}
        >
          <Typography variant="h3" component="h3" sx={titleStyle}>
            Why choose us?
            <span></span>
          </Typography>

          <Typography
            component="p"
            sx={{
              color: "#606060",
              fontFamily: "Open Sans",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",

              display: "inline-block",
              marginBottom: isMobile ? "20px" : "26px"
            }}
          >
            <b>Hanfincal.com</b> is a partner who seeks to earn your trust in
            finding a suitable loan from a reliable lender.
          </Typography>
          <ListCard />
        </Box>
      </Box>
    </Box>
  );
};

export default WhyChooseUS;
