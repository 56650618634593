import "./Configuration.scss";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { checkPingResult, sendDataPhonexa } from "../../../utils/callApi";

import { Box } from "@material-ui/core";
import MyCircularProgressbar from "../../../components/CircularProgressbar/CircularProgressbar";
import { NewSteps } from "../../../components/NewSteps/NewSteps";
import NumberLoader from "../../../components/CountingComponent/CountingComponent";
import { QuestionAnswer } from "../../../components/QuestionAnswer/QuestionAnswer";
import active3 from "../../../assets/images/configuration/progress/3-active.png";
import active4 from "../../../assets/images/configuration/progress/4-active.png";
import newIcon1 from "../../../assets/images/configuration/progress/11.png";
import newIcon2 from "../../../assets/images/configuration/progress/22.png";
import newIcon3 from "../../../assets/images/configuration/progress/33.png";
import { useSelector } from "react-redux";

// import active1 from "../../../assets/images/configuration/progress/1-active.png";
// import active2 from "../../../assets/images/configuration/progress/2-active.png";

const questionsAnswerData = [
  {
    questionNumber: 1,
    question:
      "Do you have more than 15k in credit card and personal loan debts?",
    answer: [
      {
        name: "Yes",
        value: "yes",
      },
      {
        name: "No",
        value: "no",
      },
    ],
  },
  {
    questionNumber: 2,
    question: "Looking to save on auto insurance?",
    answer: [
      {
        name: "Yes",
        value: "yes",
      },
      {
        name: "No",
        value: "no",
      },
    ],
  },
  {
    questionNumber: 3,
    question: "Are you looking to improve your credit score?",
    answer: [
      {
        name: "Yes",
        value: "yes",
      },
      {
        name: "NO",
        value: "no",
      },
    ],
  },
  {
    questionNumber: 4,
    question: "Thank you for your time! Check your inbox for updates.",
    answer: [
      {
        name: "Got it",
        value: "Got it",
      },
    ],
    // icon: checkedIcon
  },
];

const newSteps = [
  {
    index: 1,
    icon: newIcon1,
    name: "Formation of the request",
  },
  {
    index: 2,
    icon: newIcon2,
    name: "Connection setup",
  },
  {
    index: 3,
    icon: newIcon3,
    name: "Start the process",
  },
];
const steps = [
  // {
  //   icon: active1,
  //   name: "Verifying Loan Request",
  //   verified: "Verify Loan Request",
  //   verifiedComplete: "Verified!",
  //   timeCompleted: 3000
  // },
  // {
  //   icon: active2,
  //   name: "Analyzing your information",
  //   verified: "Analysing your information",
  //   verifiedComplete: "Analysed!",
  //   timeCompleted: 3100
  // },
  {
    icon: active3,
    name: "Searching Lenders",
    verified: "Searching Lenders",
    verifiedComplete: "Verified!",
    timeCompleted: 193000,
  },
  {
    icon: active4,
    name: "Connecting Lenders",
    verified: "Connecting Lenders 4",
    verifiedComplete: "Congratulations! You are connecting with the Lender.",
    timeCompleted: 500,
  },
];
const Configuration = () => {
  const isMobile = window.innerWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [step, setStep] = useState(0);
  const [callApiComplete, setCallApiComplete] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [showNewSteps, setShowNewSteps] = useState(true);
  const [showAndHideQuestion, setShowAndHideQuestion] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const showConfigurationText = false;

  const [pingRespond, setPingRespond] = useState(null);
  const [checkKey, setCheckKey] = useState("");

  const [questionSelected, setQuestionSelected] = useState(
    questionsAnswerData[0]
  );

  const [newStepsStatus, setNewStepsStatus] = useState([
    "active",
    "ready",
    "ready",
  ]);

  const [reachTotalTimeout, setReachTotalTimeoutLimit] = useState(false);

  const handleLocation = (obj) => {
    const redirectURL = obj?.redirect_url ?? obj?.rejectUrl;
    if (redirectURL) {
      window.location.href = redirectURL;
    }
    setCallApiComplete(true);
  };

  const handlePhonexaApi = async () => {
    setCallApiComplete(false);
    try {
      const res = await sendDataPhonexa(contactRedux);
      const { data } = res?.data;

      if (data?.status === 3) {
        setCheckKey(data?.lead_id);
        handlePingApi(data?.lead_id);
      } else {
        handleLocation(res);
      }
    } catch (error) {
      setCallApiComplete(true);
      throw error;
    }
  };

  const handlePingApi = async (checkKey) => {
    try {
      const defaultLeadId = sessionStorage.getItem("lead_id") ?? contactRedux.lead_id;
      const utm_source = sessionStorage.getItem("utm_source") ?? contactRedux.utm_source;
      const res = await checkPingResult(defaultLeadId, checkKey, utm_source);

      if (res) {
        const { data } = res?.data;
        setPingRespond(data);
      }
    } catch (error) {
      setCallApiComplete(true);
      throw error;
    }
  };

  useEffect(() => {
    handlePhonexaApi();
    setTimeout(() => {
      setReachTotalTimeoutLimit(true);
    }, 310000);
  }, []);

  useEffect(() => {
    if (checkKey && pingRespond) {
      if (pingRespond?.status === 3 && !reachTotalTimeout) {
        const timer = setTimeout(
          () => handlePingApi(checkKey),
          parseInt(process.env.REACT_APP_LEAD_PING_INTERVAL)
        );
        return () => clearTimeout(timer);
      }
      if (pingRespond?.status === 1 || pingRespond?.status === 2) {
        axios
          .post(
            `${process.env.REACT_APP_INHOUSE_API_BASE_URL}/v1/fe/logs`,
            { pretext: "Ping response:", text: pingRespond },
            { headers: { "content-type": "application/json" } }
          )
          .then();
        handleLocation(pingRespond);
      }
    }
  }, [pingRespond, checkKey]);

  useEffect(() => {
    if (showProgress) {
      if (step < steps.length) {
        const timer = setTimeout(() => {
          setStep(step + 1);
        }, steps[step]?.timeCompleted + 500 || 500);

        return () => clearTimeout(timer);
      }
    }
  }, [step, showProgress]);

  return (
    <Box
      className="configuration-page"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      {/* <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav> */}

      <div className="step-main">
        <div className="container">
          <h1 className="h1-title">Processing your request</h1>
          <h5 className="h5-desc">
            {" "}
            Please be patient, this process may take several minutes.
          </h5>
          <h3 className="h3-desc">
            Do NOT leave this page - Do NOT refresh your browser
          </h3>
          <h3 className="h3-desc">
            HOLD tight to get connected with the lender!
          </h3>
          <Box
            sx={{
              width: "100%",
              maxWidth: "900px",
              padding: isMobile ? "25px 15px 30px" : "41px 30px 52px",
              boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid #06D6A0 ",
              backgroundColor: "#fff",
            }}
          >
            {showNewSteps && (
              <NewSteps
                newSteps={newSteps}
                newStepsStatus={newStepsStatus}
                setNewStepsStatus={setNewStepsStatus}
                setShowNewSteps={setShowNewSteps}
                setShowProgress={setShowProgress}
                callApiComplete={callApiComplete}
              />
            )}

            {showConfigurationText && (
              <Box
                sx={{
                  fontSize: "20px",
                  color: "#00647A",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                <span
                  style={{
                    color: "#FCA905",
                  }}
                >
                  Congratulations!
                  <br />
                </span>{" "}
                You are connecting with the Trusted Partner.
              </Box>
            )}

            {showProgress && (
              <MyCircularProgressbar
                timeCompleted={
                  callApiComplete ? 1000 : steps[step]?.timeCompleted
                }
                // verified={steps[step].verified}
                // verifiedComplete={steps[step].verifiedComplete}
                step={step}
                callApiComplete={callApiComplete}
                isVerified={isVerified}
                setIsVerified={setIsVerified}
              />
            )}

            {step === 0 && steps[step].verified && (
              <Box className="box-searching">
                <p
                  component="h3"
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: isMobile ? "12px" : "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Searching amount:{" "}
                  <span style={{ color: "#FCA905" }}>
                    $
                    {contactRedux?.loanAmount ??
                      contactRedux?.loan_amount ??
                      contactRedux?.amount ??
                      0}
                  </span>
                </p>
                <p
                  style={{
                    color: "#606060",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: isMobile ? "10px" : "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <NumberLoader />
                  /10 Please wait, we require more time to process your request.
                </p>
              </Box>
            )}
            {showAndHideQuestion && (
              <div className="box-question-answer">
                <QuestionAnswer
                  questionsAnswerData={questionsAnswerData}
                  questionSelected={questionSelected}
                  contactRedux={contactRedux}
                  setQuestionSelected={setQuestionSelected}
                  setShowAndHideQuestion={setShowAndHideQuestion}
                />
              </div>
            )}
            <p className="desc-configuration">
              We will <b>REDIRECT YOU DIRECTLY TO THE LENDER</b> when your
              application is processed successfully. In regard to your rights,
              you should review and acknowledge all policies, terms &
              conditions, rates, fee,...provided by the lender. You are under no
              obligation to accept all offered products/services. This content
              is for informational purposes only and does not work as legal
              consultation or advice.
            </p>
          </Box>
        </div>
      </div>

      <footer>
        <p>© Copyright 2022 HANFINCAL</p>
      </footer>
    </Box>
  );
};

export default Configuration;
