import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Partners.scss";

const Partners = () => {

  const partners = [
    "AlumAds",
    "Solution BPO",
    "X1 Info Tech",
    "JG Wentworth",
    "Stop Go Networks",
    "Dot818",
    "Lead Network",
    "Round Sky",
    "Leap Theory",
    "Teapot",
    "ITMEDIA",
    "LeadsMarket",
    "PingLogix",
    "UpAds",
    "Intimate Interactive",
    "Teapot Media",
    "Leadtree Global",
    "Interlincx Media",
    "Freshway Media",
    "Lead Avenue",
    "Affilic Digital",
    "Boomsourcing",
    "MaxCash",
    "Big Step Marketing",
    "Reliable Leads",
    "RevJolt",
    "Complete Home Services",
    "Insurance Guide",
    "National Association of Chiefs of Police",
    "Autistic Children of America",
    "ITP",
    "Infinity One",
    "Quinstreet",
    "Cane Bay",
    "Gulf Coast Leads",
    "Media Blanket",
    "Liftup Capital",
    "UniFi Funding",
    "AAA Auto Title Loans, LLC",
    "CASH 1, LLC (NV)",
    "CASH 1 UT, LLC",
    "CASH 1 ID, LLC",
    "CASH 1 KS, LLC",
    "CASH 1 MO, LLC",
    "CASH 1 LA, LLC",
    "ArcaMax Publishing"
  ]
  
  return (
    <div className="credit-authorization-agreement">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Ours partners</h2>
          <p class="partner-list">
            { partners.join(', ')}.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Partners;
